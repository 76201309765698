import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Animate from '../../components/Animate.jsx';
import AnimateChangeUp from '../../components/AnimateChangeUp.jsx';
import BorderDashed from '../../components/BorderDashed.jsx';
import Button from '../../components/Button.jsx';
import Field from '../../components/Field.jsx';
import Icon from '../../components/Icon.jsx';
import ListAbsoluteMain from '../../components/ListAbsoluteMain.jsx';
import Loader from '../../components/Loader.jsx';
import Select from '../../components/Select.jsx';
import Input from '../../components/v2/input/Input.tsx';

import checkAuth from '../../functions/app/checkAuth';
import handlerPopup from '../../functions/app/handlerPopup';
import bankCardValidate from '../../functions/bankCardValidate';
import changePage from '../../functions/changePage';
import checkValueOfEmpty from '../../functions/checkValueOfEmpty';
import connectSockets from '../../functions/connectSockets';
import getHeaders from '../../functions/getHeaders';
import getPageLink from '../../functions/getPageLink';
import getUpdateFormData from '../../functions/getUpdateFormData';
import getUserInfo from '../../functions/getUserInfo';
import { deleteCookie, setCookie } from '../../functions/handlerCookies';
import { inputValidate } from '../../functions/inputValidate';
import setAnimate from '../../functions/setAnimate';
import setNotification from '../../functions/setNotification';
import setServerData from '../../functions/setServerData';

import File from '../../classes/File';
import { dispatcher } from '../../redux/redux';
import Card from './join/Card.jsx';
import Check from './join/Check.jsx';
import Code from './join/Code.jsx';
import Docs from './join/Docs.jsx';
import Final from './join/Final.jsx';
import Form from './join/Form.jsx';
import Phone from './join/Phone.jsx';
import Start from './join/Start.jsx';
import Status from './join/Status.jsx';

const selects = require('../../infos/selects.json');

class Join extends React.Component {
    constructor(props) {
        super(props);

        let currentStep = localStorage.getItem('crmJoinStep') || 'start';

        if (localStorage.getItem('crmJoinPhone')) {
            currentStep = 'code';
        }

        if (localStorage.getItem('crmJoinCode')) {
            currentStep = localStorage.getItem('crmJoinStep') || 'status';
        }

        if (localStorage.getItem('crmJoinCheck')) {
            currentStep = 'check';
        }

        if (localStorage.getItem('crmJoinComplete')) {
            currentStep = 'final';
        }

        this.state = {
            currentStep,
            files: {},
            currentOrganization: localStorage.getItem('crmJoinOrganization') || null,
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.renderField = this.renderField.bind(this);
        this.handlerField = this.handlerField.bind(this);
        this.renderButton = this.renderButton.bind(this);
        this.renderDoc = this.renderDoc.bind(this);
        this.setCurrentStep = this.setCurrentStep.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.checkCode = this.checkCode.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.setUpdateKey = this.setUpdateKey.bind(this);
        this.handlerButton = this.handlerButton.bind(this);
        this.setOrganizationStatus = this.setOrganizationStatus.bind(this);
        this.renderTelegram = this.renderTelegram.bind(this);
        this.handlerStatusError = this.handlerStatusError.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.handlerErrors = this.handlerErrors.bind(this);
        this.getLink = this.getLink.bind(this);
        this.handlerLoadingButton = this.handlerLoadingButton.bind(this);
        this.handlerFns = this.handlerFns.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);
        this.getCorporation = this.getCorporation.bind(this);
        this.changeWalletType = this.changeWalletType.bind(this);

        this.parent = React.createRef();
    }

    tabsOrder = ['start', 'phone', 'code', 'status', 'anket', 'docs', 'card', 'check', 'final'];

    tabs = {
        start: {
            content: 'Приветствие',
        },
        phone: {
            content: 'Телефон',
        },
        code: {
            content: 'Подтверждение',
        },
        status: {
            content: 'Статус',
        },
        anket: {
            content: 'Анкета',
        },
        docs: {
            content: 'Документы',
        },
        card: {
            content: 'Карта',
        },
        check: {
            content: 'Проверка',
        },
        final: {
            content: 'Готово',
        },
    };

    fields = {
        phone: {
            support: 'Телефон',
            inputSupport: 'Введите ваш номер телефона',
        },
        firstName: {
            support: 'Имя',
        },
        secondName: {
            support: 'Фамилия',
        },
        thirdName: {
            support: 'Отчество',
            isRequire: false,
        },
        category: {
            support: 'Выберите категорию',
            type: 'select',
            selectName: 'executorTypes',
        },
        telegram: {
            support: 'Телеграм',
            isRequire: false,
        },
        inn: {
            support: 'ИНН',
            isRequire: false,
        },
        city: {
            support: 'Выберите город работы',
            type: 'select',
            selectName: 'executorTowns',
            inServerData: true,
        },
        nationality: {
            support: 'Выберите ваше гражданство',
            type: 'select',
            selectName: 'executorPassportCountries',
            inServerData: true,
        },
        numberCard: {
            support: 'Карта',
        },
        bic: {
            support: 'БИК',
        },
        bankAccount: {
            support: 'Номер счёта',
        },
        addressatName: {
            support: 'ФИО счёта',
        },
        'smz-phone': {
            support: 'Телефон',
            keyName: 'phone',
            inputSupport: 'Введите ваш номер телефона',
        },
        'smz-inn': {
            support: 'ИНН',
            keyName: 'inn',
            isRequire: false,
        },
    };

    blocks = {
        start: {
            render() {
                const { fieldsUpdateKey, corporationName } = this.state;

                return (
                    <Start
                        renderButton={this.renderButton}
                        corporationName={corporationName}
                        fieldsUpdateKey={fieldsUpdateKey}
                        renderTelegram={this.renderTelegram}
                    />
                );
            },
        },
        phone: {
            render() {
                const { fieldsUpdateKey, corporationName } = this.state;
                const link = this.getLink();

                return (
                    <Phone
                        corporationName={corporationName}
                        fieldsUpdateKey={fieldsUpdateKey}
                        renderField={this.renderField}
                        renderButton={this.renderButton}
                        link={link}
                    />
                );
            },
        },
        code: {
            render() {
                const { fieldsUpdateKey, loadingButton } = this.state;

                return (
                    <Code
                        fieldsUpdateKey={fieldsUpdateKey}
                        renderField={this.renderField}
                        renderButton={this.renderButton}
                        setCurrentStep={this.setCurrentStep}
                        checkCode={this.checkCode}
                        handlerButton={this.handlerButton}
                        loadingButton={loadingButton}
                    />
                );
            },
        },
        status: {
            render() {
                const {
                    fields,
                    joinScript,
                    fieldsUpdateKey,
                    loadingButton,
                    currentOrganization,
                    statusError,
                    fnsStatus,
                } = this.state;

                return (
                    <Status
                        fieldsUpdateKey={fieldsUpdateKey}
                        renderField={this.renderField}
                        renderButton={this.renderButton}
                        setCurrentStep={this.setCurrentStep}
                        checkCode={this.checkCode}
                        handlerButton={this.handlerButton}
                        loadingButton={loadingButton}
                        joinScript={joinScript}
                        currentOrganization={currentOrganization}
                        setOrganizationStatus={this.setOrganizationStatus}
                        setUpdateKey={this.setUpdateKey}
                        statusError={statusError}
                        handlerStatusError={this.handlerStatusError}
                        scrollToTop={this.scrollToTop}
                        fields={fields}
                        handlerErrors={this.handlerErrors}
                        getLink={this.getLink}
                        handlerLoadingButton={this.handlerLoadingButton}
                        fnsStatus={fnsStatus}
                        handlerFns={this.handlerFns}
                    />
                );
            },
        },
        anket: {
            render() {
                const { joinScript, fieldsUpdateKey } = this.state;

                return (
                    <Form
                        fieldsUpdateKey={fieldsUpdateKey}
                        renderField={this.renderField}
                        renderButton={this.renderButton}
                        setCurrentStep={this.setCurrentStep}
                        joinScript={joinScript}
                    />
                );
            },
        },
        docs: {
            render() {
                const { fieldsUpdateKey } = this.state;

                return (
                    <Docs
                        fieldsUpdateKey={fieldsUpdateKey}
                        renderDoc={this.renderDoc}
                        renderButton={this.renderButton}
                        setCurrentStep={this.setCurrentStep}
                        docs={this.getDocs()}
                    />
                );
            },
        },
        card: {
            render() {
                const { joinScript, fields, fieldsUpdateKey, currentWalletType } = this.state;

                return (
                    <Card
                        fieldsUpdateKey={fieldsUpdateKey}
                        renderField={this.renderField}
                        renderButton={this.renderButton}
                        setCurrentStep={this.setCurrentStep}
                        error={fields?.numberCard?.error}
                        setUpdateKey={this.setUpdateKey}
                        joinScript={joinScript}
                        currentWalletType={currentWalletType}
                        changeWalletType={this.changeWalletType}
                    />
                );
            },
        },
        check: {
            render() {
                const { fieldsUpdateKey } = this.state;

                return (
                    <Check
                        fieldsUpdateKey={fieldsUpdateKey}
                        renderField={this.renderField}
                        renderButton={this.renderButton}
                        setCurrentStep={this.setCurrentStep}
                        renderTelegram={this.renderTelegram}
                    />
                );
            },
        },
        final: {
            render() {
                const { joinScript, fieldsUpdateKey, hasButton } = this.state;

                return (
                    <Final
                        fieldsUpdateKey={fieldsUpdateKey}
                        renderField={this.renderField}
                        renderButton={this.renderButton}
                        setCurrentStep={this.setCurrentStep}
                        renderTelegram={this.renderTelegram}
                        joinScript={joinScript}
                        hasButton={hasButton}
                    />
                );
            },
        },
    };

    changeWalletType({ type }) {
        this.setState({ currentWalletType: type });
    }

    getDocs() {
        const { fields, executorInfo, joinScript } = this.state;

        return executorInfo?.docs.filter((doc) => {
            const joinScriptDoc = joinScript.docs.find((innerDoc) => innerDoc.id === doc.key);

            if (joinScriptDoc.countries.length === 0) {
                return true;
            }

            let cond = false;

            if (joinScriptDoc.countries.length === 0) {
                cond = true;
            } else {
                joinScriptDoc.countries.forEach((country) => {
                    if (country === 'RF' && fields.nationality.value === 'rossiya') {
                        cond = true;
                    }

                    if (
                        country === 'EAES' &&
                        this.EAESCountries.includes(fields.nationality.value)
                    ) {
                        cond = true;
                    }

                    if (
                        country === 'other' &&
                        !['rossiya', ...this.EAESCountries].includes(fields.nationality.value)
                    ) {
                        cond = true;
                    }
                });
            }

            return cond;
        });
    }

    handlerFns({ fnsStatus, executorInfo, fnsPhone }) {
        this.setState(
            (state) => {
                const newState = { ...state };
                const fields = JSON.parse(JSON.stringify(newState.fields));

                if (executorInfo) {
                    ['firstName', 'secondName', 'thirdName'].forEach((key) => {
                        fields[key].value = executorInfo[key];
                    });

                    fields.addressatName.value = getUserInfo({
                        type: 'name',
                        user: executorInfo,
                    });
                }

                if (fnsPhone) {
                    fields.phone.value = fnsPhone;
                }

                if (fnsStatus !== undefined) {
                    newState.fnsStatus = fnsStatus;
                }

                newState.fields = fields;

                newState.fieldsUpdateKey = new Date().getTime();

                return newState;
            },
            () => {
                if (fnsPhone) {
                    localStorage.setItem('crmJoinPhone', fnsPhone);
                }

                localStorage.setItem('crmJoinFields', JSON.stringify(this.state.fields));
            },
        );
    }

    setOrganizationStatus({ status }) {
        const currentOrganization = this.state.currentOrganization === status ? null : status;

        if (currentOrganization) {
            localStorage.setItem('crmJoinOrganization', currentOrganization);
        } else {
            localStorage.removeItem('crmJoinOrganization');
        }

        this.setState({
            currentOrganization,
            statusError: null,
        });
    }

    setUpdateKey() {
        this.setState({ updateKey: new Date().getTime() });
    }

    setCurrentStep(currentStep) {
        this.setState({ currentStep }, () => {
            if (currentStep === 'phone') {
                this.handlerField({ action: 'change', name: 'phone', value: '' });
            }

            localStorage.setItem('crmJoinStep', currentStep);

            this.checkTabsPosition();
        });
    }

    getBlocks(isAll) {
        const { currentStep } = this.state;

        if (isAll) {
            return this.tabsOrder;
        }

        return [{ key: currentStep }];
    }

    renderBlock({ prop: key }) {
        const block = this.blocks[key];

        return <div className={`join__block _col _${key}`}>{block?.render.call(this)}</div>;
    }

    handlerErrors({ errors = [], action }) {
        this.setState((state) => {
            const newState = { ...state };
            const fields = JSON.parse(JSON.stringify(newState.fields));

            errors.forEach(({ name, error = 'validate' }) => {
                if (fields[name]) {
                    fields[name].error = !action ? error : null;
                }
            });

            newState.fields = fields;

            newState.fieldsUpdateKey = new Date().getTime();

            return newState;
        });
    }

    handlerField({ action, name, value, error }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                if (action === 'focus') {
                    dispatcher({ type: 'isInputFocus', data: true });
                }
                if (action === 'blur') {
                    dispatcher({ type: 'isInputFocus', data: false });
                }

                resolve();
            } else {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const fields = JSON.parse(JSON.stringify(newState.fields));

                        if (checkValueOfEmpty(value, true)) {
                            fields[name].value = value;
                            fields[name].error = null;
                        }

                        if (checkValueOfEmpty(error)) {
                            fields[name].error = error;
                        }

                        newState.fields = fields;

                        newState.fieldsUpdateKey = new Date().getTime();

                        return newState;
                    },
                    () => {
                        localStorage.setItem('crmJoinFields', JSON.stringify(this.state.fields));

                        resolve();
                    },
                );
            }
        });
    }

    initFields() {
        const { executorInfo } = this.state;
        const jsonSavedFields = localStorage.getItem('crmJoinFields');
        const savedFields = jsonSavedFields ? JSON.parse(jsonSavedFields) : {};

        this.setState((state) => {
            const newState = { ...state };
            const fields = savedFields;

            Object.keys(this.fields).forEach((key) => {
                if (!fields[key]) {
                    fields[key] = {
                        value: this.fields[key].type === 'select' ? null : '',
                        error: null,
                    };
                }
            });

            if (executorInfo) {
                ['secondName', 'firstName', 'thirdName'].forEach((key) => {
                    if (executorInfo[key]) {
                        fields[key].value = executorInfo[key];
                    }
                });

                if (executorInfo.type) {
                    fields.category.value = executorInfo.type;
                }

                fields.addressatName.value = getUserInfo({ type: 'name', user: executorInfo });
            }

            if (localStorage.getItem('crmJoinPhone')) {
                fields.phone.value = localStorage.getItem('crmJoinPhone');
            }

            if (process.env.REACT_APP_ENV !== 'prod') {
                fields.bic.value = '040407627';
                fields.bankAccount.value = '40817810831001553040';
            }

            newState.fieldsUpdateKey = new Date().getTime();
            newState.fields = fields;

            return newState;
        });
    }

    renderField({ name }) {
        const { fields, joinScript, getedCorporationKey } = this.state;
        const { executorOrganization } = joinScript;
        const { serverData } = this.props;
        const field = this.fields[name];
        const { type, selectName, inServerData } = field;
        let items = JSON.parse(
            JSON.stringify(
                (inServerData ? serverData[selectName] : selects[selectName]?.items) || [],
            ),
        );
        const keyName = field.keyName || name;
        let isDisabled;
        let value = fields?.[name]?.value;

        if (name === 'category' && 0) {
            items = items.filter(
                (item) => !['driver', 'worker', 'auto-courier', 'builder'].includes(item.key),
            );
        }

        if (executorOrganization === 'SMZ') {
            if (['firstName', 'secondName', 'thirdName'].includes(name)) {
                isDisabled = true;
            }
        }

        if (name === 'smz-phone') {
            isDisabled = true;
            value = fields?.phone?.value;
        }

        if (name === 'addressatName') {
            isDisabled = true;
        }

        return (
            <div
                className={`join__blockField _${name} ${fields?.[name]?.error ? '_error' : ''}`}
                key={name}
            >
                <div className="join__blockFieldBox">
                    {type === 'select' ? (
                        <Select
                            type={selectName}
                            support={field.support}
                            keyName={keyName}
                            name={name}
                            value={value}
                            error={fields?.[name]?.error}
                            callback={this.handlerField}
                            className={`_notBack _join ${name === 'category' ? '' : '_reverse'}`}
                            id={name}
                            items={items}
                            data={{
                                executorOrganization,
                            }}
                        />
                    ) : name.includes('phone') ? (
                        <>
                            <Input
                                value={value}
                                disabled={isDisabled}
                                onChange={({ value: updValue }) => {
                                    this.handlerField({
                                        action: 'change',
                                        name,
                                        value: updValue,
                                    });
                                }}
                                reg="phone"
                                returnTemplate={true}
                                className="_center _textGrey"
                                support={field.inputSupport}
                                error={!!fields?.[name]?.error}
                                updatedKey={getedCorporationKey}
                            />
                        </>
                    ) : (
                        <Field
                            // support=""
                            type="join"
                            keyName={keyName}
                            name={name}
                            value={value}
                            callback={this.handlerField}
                            className="_center _notBack"
                            id={name}
                            isDisabled={isDisabled}
                        />
                    )}
                </div>
                {name === 'numberCard' && executorOrganization !== 'SMZ' && (
                    <p className="join__blockFieldDescription">
                        Банковская комиссия при переводе на карту 1,5%,
                        <br />
                        но не менее 30 рублей
                    </p>
                )}
            </div>
        );
    }

    formData = new FormData();

    uploadFile({ fileId, target }) {
        const { loadingButton, fields } = this.state;
        const link = this.getLink();

        if (!loadingButton) {
            this.handlerLoadingButton(fileId).then(() => {
                this.handlerFile.uploadFiles({ target, names: [fileId] }).then(
                    ({ resultFiles: files }) => {
                        const [file] = files;

                        const formData = new FormData();

                        formData.set(fileId, file.object);
                        formData.set('link', link);
                        formData.set('phone', fields?.phone.value);
                        formData.set('code', localStorage.getItem('crmJoinCode'));

                        axios
                            .put(`${process.env.REACT_APP_API}/join`, getUpdateFormData(formData), {
                                headers: getHeaders(),
                            })
                            .then(
                                (res) => {
                                    const { success, data } = res.data;

                                    if (success) {
                                        const { file: uploadedFile } = data;

                                        this.setState((state) => {
                                            const newState = { ...state };
                                            const executorInfo = JSON.parse(
                                                JSON.stringify(newState.executorInfo),
                                            );

                                            const docIndex = executorInfo.docs.findIndex((doc) =>
                                                doc.files.find(
                                                    (innerFile) => innerFile._id === fileId,
                                                ),
                                            );
                                            const fileIndex = executorInfo.docs[
                                                docIndex
                                            ].files.findIndex(
                                                (innerFile) => innerFile._id === fileId,
                                            );

                                            if (docIndex !== -1) {
                                                executorInfo.docs[docIndex].files[fileIndex].file =
                                                    uploadedFile;
                                            }

                                            newState.executorInfo = executorInfo;
                                            newState.fieldsUpdateKey = new Date().getTime();

                                            return newState;
                                        });
                                    }

                                    this.handlerLoadingButton(null);
                                },
                                () => null,
                            );
                    },
                    () => {
                        this.handlerLoadingButton(null);
                    },
                );
            });
        }
    }

    renderDoc({ name, files = [] }) {
        const { loadingButton, filesErrors = [] } = this.state;
        const uploadedFilesCounter = files.filter((file) => file.file.path).length;

        return (
            <div className="join__blockField _file">
                <div className="join__blockFieldBox">
                    <div className="join__blockDoc">
                        <div className="join__blockDocHead">
                            {name}
                            <AnimateChangeUp
                                className="join__blockDocCounter"
                                renderKey={uploadedFilesCounter}
                            >
                                <>
                                    {uploadedFilesCounter}/{files.length}
                                </>
                            </AnimateChangeUp>
                        </div>
                        <div className="join__blockDocContent _row">
                            {files.map((file) => {
                                const path = file.file.path;
                                const fileName = file.file.name;
                                const isUploadedFile = !!path;
                                const isLoading = loadingButton === file._id;
                                const isError = filesErrors.includes(file._id);

                                return (
                                    <label
                                        className={`join__blockDocFile _col _click ${
                                            files.length === 1 ? '_full' : ''
                                        } ${isLoading ? '_loading' : ''} ${
                                            isUploadedFile ? '_isUpload' : ''
                                        } ${isError ? '_error' : ''}`}
                                        key={file._id}
                                    >
                                        <div className="join__blockDocFileBack">
                                            <BorderDashed className="_upload" />
                                        </div>
                                        <input
                                            type="file"
                                            className="join__blockDocFileInput"
                                            disabled={!!loadingButton}
                                            onChange={({ target }) =>
                                                this.uploadFile({ target, fileId: file._id })
                                            }
                                        />
                                        <div className="join__blockDocFilePreview _col">
                                            <i className="join__blockDocFilePreviewIcon">
                                                <Icon name="add" />
                                            </i>
                                            <p className="join__blockDocFilePreviewDescription">
                                                Загрузите
                                                <br />
                                                файл
                                            </p>
                                        </div>
                                        <Animate
                                            className="join__blockDocFileLoader _loader"
                                            isShow={isLoading}
                                        >
                                            <div className="join__blockDocFileLoaderItem _loaderItem">
                                                <Loader className="_main" />
                                            </div>
                                        </Animate>
                                        <Animate
                                            className="join__blockDocFileInfo _col"
                                            isShow={isUploadedFile}
                                        >
                                            <i className="join__blockDocFileInfoIcon">
                                                <Icon name="file-type-doc" />
                                            </i>
                                            <div className="join__blockDocFileInfoName">
                                                {fileName}
                                            </div>
                                        </Animate>
                                    </label>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getContentHeight() {
        if (this.parent.current) {
            const { heightWindow = 0 } = this.props;
            const head = this.parent.current.querySelector('.join__head');
            const { offsetHeight: headHeight } = head;

            return heightWindow - headHeight;
        }

        return 0;
    }

    checkCode(code) {
        const { fields } = this.state;
        const link = this.getLink();
        const phone = fields?.phone?.value;

        return new Promise((resolve) => {
            axios
                .post(
                    `${process.env.REACT_APP_API}/join`,
                    {
                        link,
                        phone,
                        code,
                    },
                    {
                        headers: getHeaders(),
                    },
                )
                .then((res) => {
                    const { success, data } = res.data;

                    if (success) {
                        const { executorInfo, isPolicyConfirm, hash } = data;

                        if (hash) {
                            if (process.env.REACT_APP_SYSTEM === 'app') {
                                this.toCabinet({ hash, pageHref: 'corporations-main' });
                            }
                        } else {
                            localStorage.setItem('crmJoinCode', code);

                            if (isPolicyConfirm === true) {
                                this.setFinal();
                            } else {
                                this.setCurrentStep('status');

                                this.setState({ executorInfo }, () => {
                                    this.initFields();
                                });
                            }
                        }

                        resolve(true);
                    } else {
                        const { message } = data;

                        if (message === 'Join not found') {
                            // this.setState({ isJoinScriptError: true });
                        }

                        resolve(false);
                    }
                });
        });
    }

    setFilesErrors({ errors, action }) {
        this.setState((state) => {
            const newState = { ...state };
            const filesErrors = [...(newState.filesErrors || [])];

            if (!action) {
                errors.forEach((error) => {
                    if (!filesErrors.includes(error)) {
                        filesErrors.push(error);
                    }
                });
            } else {
                errors.forEach((error) => {
                    const errorIndex = filesErrors.indexOf(error);

                    if (errorIndex !== -1) {
                        filesErrors.splice(errorIndex, 1);
                    }
                });
            }

            newState.filesErrors = filesErrors;

            newState.fieldsUpdateKey = new Date().getTime();

            return newState;
        });
    }

    EAESCountries = ['armeniya', 'belorussiya', 'kazahstan', 'kirgiziya', 'ukraina'];

    checkDocs() {
        const { executorInfo, joinScript, fields } = this.state;
        const errors = [];
        const nationality = fields.nationality.value;

        executorInfo.docs.forEach((doc) => {
            const joinScriptDoc = joinScript.docs.find((innerDoc) => innerDoc.id === doc.key);

            let isDocNeed = false;

            if (joinScriptDoc.countries.length === 0) {
                isDocNeed = true;
            } else {
                joinScriptDoc.countries.forEach((country) => {
                    if (country === 'RF' && nationality === 'rossiya') {
                        isDocNeed = true;
                    }

                    if (country === 'EAES' && this.EAESCountries.includes(nationality)) {
                        isDocNeed = true;
                    }

                    if (
                        country === 'other' &&
                        !['rossiya', ...this.EAESCountries].includes(nationality)
                    ) {
                        isDocNeed = true;
                    }
                });
            }

            if (joinScriptDoc.isRequire === true && isDocNeed) {
                doc.files.forEach((file) => {
                    if (!file.file.path) {
                        errors.push(file._id);
                    }
                });
            }
        });

        return { errors };
    }

    handlerLoadingButton(loadingButton) {
        return new Promise((resolve) => {
            this.setState({ loadingButton, fieldsUpdateKey: new Date().getTime() }, resolve);
        });
    }

    setStatus({ name, status }) {
        const { fields } = this.state;
        const link = this.getLink();
        const code = localStorage.getItem('crmJoinCode');
        const resultFields = {};

        Object.keys(fields).forEach((key) => {
            resultFields[key] = fields[key]?.value;
        });

        return new Promise((resolve) => {
            this.handlerLoadingButton(name).then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/join`,
                        {
                            link,
                            phone: fields?.phone.value,
                            code,
                            status,
                            fields: resultFields,
                        },
                        {
                            headers: getHeaders(),
                        },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                // this.setCurrentStep('docs');

                                resolve();
                            }

                            this.handlerLoadingButton(null);
                        },
                        () => null,
                    );
            });
        });
    }

    handlerStatusError({ statusError }) {
        this.setState({ statusError });
    }

    toCabinet({ name, hash, pageHref = 'index' }) {
        const id = localStorage.getItem('appIdFromJoin');

        this.handlerLoadingButton(name).then(() => {
            dispatcher({ type: 'isProccessLogin', data: true }).then(() => {
                setCookie(process.env.REACT_APP_HASH, hash);

                checkAuth(true, { id }).then(() => {
                    this.handlerLoadingButton(null);

                    changePage({
                        href: getPageLink({ name: pageHref }),
                    }).then(() => {
                        setTimeout(() => {
                            this.clear();
                        }, 300);

                        if (this.props.isNotApp) {
                            setTimeout(() => {
                                handlerPopup({
                                    name: 'appInstructionApplicationPopup',
                                    isShow: true,
                                });
                            }, 3_000);
                        }

                        dispatcher({
                            type: 'isProccessLogin',
                            data: false,
                        });
                    });
                });
            });
        });
    }

    handlerButton({ name, isForce }) {
        const { currentOrganization, fields, loadingButton } = this.state;
        const link = this.getLink();
        const oldPhone = localStorage.getItem('crmJoinPhone');
        const code = localStorage.getItem('crmJoinCode');

        if (loadingButton) {
            return;
        }

        if (name === 'to-cabinet') {
            const hash = localStorage.getItem('appHashFromJoin');

            this.toCabinet({ name, hash });
        } else if (name === 'start') {
            this.setCurrentStep('phone');
        } else if (name === 'checkPhone') {
            const phone = fields?.phone?.value;

            if (!inputValidate({ name: 'phone', value: phone })) {
                this.handlerField({ action: 'change', name: 'phone', error: 'validate' });
            } else if (oldPhone === phone && !isForce) {
                this.setCurrentStep('code');
            } else {
                this.handlerLoadingButton(name).then(() => {
                    let query = `link=${link}&phone=${phone}`;

                    if (oldPhone && oldPhone !== phone) {
                        query += `&oldPhone=${oldPhone}`;
                    }

                    axios
                        .get(`${process.env.REACT_APP_API}/join?${query}`, {
                            headers: getHeaders(),
                        })
                        .then(
                            (res) => {
                                const { success, data } = res.data;

                                if (success) {
                                    const { hash } = data;

                                    localStorage.setItem('crmJoinPhone', phone);

                                    deleteCookie(process.env.REACT_APP_HASH);
                                    setCookie('joinHash', hash);

                                    if (!isForce) {
                                        this.setCurrentStep('code');
                                        connectSockets(true);
                                    }
                                } else {
                                    const { message } = data;

                                    if (message === 'Sms limit') {
                                        setNotification({ notification: 'req-limit' });
                                    } else if (message === 'Join script not found') {
                                        this.setState({ isJoinScriptError: true });
                                    } else if (message === 'Executor already create') {
                                        setNotification({ notification: 'executor-already-reg' });
                                    } else if (message === 'Try again') {
                                        setNotification({ notification: 'try-again' });
                                    } else if (message === 'Join script is only for invite') {
                                        setNotification({
                                            notification: 'join-script-only-for-invite',
                                        });
                                    } else if (message === 'Executor cannot be invited') {
                                        setNotification({
                                            notification: 'executor-cannot-be-invited',
                                        });
                                    } else if (message === 'Phone is incorrect') {
                                        this.handlerField({
                                            action: 'change',
                                            name: 'phone',
                                            error: 'validate',
                                        });
                                    }
                                }

                                this.handlerLoadingButton(null);
                            },
                            () => null,
                        );
                });
            }
        } else if (name === 'status') {
            if (!currentOrganization) {
                this.handlerStatusError({ statusError: 'error' });
            } else {
                this.setStatus({ name, status: 'anket' }).then(() => {
                    this.setCurrentStep('anket');
                });
            }
        } else if (name === 'anket') {
            const errors = [];

            [
                'secondName',
                'firstName',
                'thirdName',
                'category',
                'telegram',
                'inn',
                'city',
                'nationality',
            ].forEach((key) => {
                const isRequire = this.fields[key].isRequire ?? true;
                const value = fields[key].value;

                if (this.fields[key].type === 'select') {
                    if (!value) {
                        errors.push({ name: key, error: 'validate' });
                    }
                } else if (!inputValidate({ name: key, value, isRequire })) {
                    errors.push({ name: key, error: 'validate' });
                }
            });

            if (errors.length) {
                this.handlerErrors({ errors });
            } else {
                this.setStatus({ name, status: 'docs' }).then(() => {
                    this.setCurrentStep('docs');
                });
            }
        } else if (name === 'docs') {
            const { errors } = this.checkDocs();

            if (errors.length) {
                this.setFilesErrors({ errors });
            } else {
                this.setStatus({ name, status: 'card' }).then(() => {
                    this.setCurrentStep('card');
                });
            }
        } else if (name === 'card') {
            const { currentWalletType } = this.state;
            const { errors: docsErrors } = this.checkDocs();
            const errors = [];

            (currentWalletType === 'card'
                ? ['numberCard']
                : ['bic', 'bankAccount', 'addressatName']
            ).forEach((key) => {
                const value = fields?.[key]?.value;

                if (!inputValidate({ name: key, value })) {
                    errors.push({ name: key });
                } else if (key === 'numberCard' && value.slice(0, 4) === '5534') {
                    errors.push({ name: key, error: 'corporate' });
                } else if (
                    key === 'numberCard' &&
                    !bankCardValidate(value) &&
                    process.env.REACT_APP_ENV === 'prod'
                ) {
                    errors.push({ name: key, error: 'luhn' });
                }
            });

            if (errors.length) {
                this.handlerErrors({ errors });
            } else if (docsErrors.length) {
                this.setFilesErrors({ errors: docsErrors });
                this.setCurrentStep('docs');
            } else {
                this.handlerLoadingButton(name).then(() => {
                    const resultFields = {};

                    Object.keys(fields).forEach((key) => {
                        resultFields[key] = fields[key].value;
                    });

                    const body = {
                        fields: resultFields,
                        link,
                        isCreate: true,
                        phone: fields?.phone.value,
                        code,
                        currentWalletType,
                    };

                    axios
                        .post(`${process.env.REACT_APP_API}/join`, body, {
                            headers: getHeaders(),
                        })
                        .then(
                            (res) => {
                                const { success, data } = res.data;

                                if (success) {
                                    const { hash, id } = data;

                                    this.setCurrentStep('check');

                                    localStorage.setItem('crmJoinCheck', true);

                                    if (hash) {
                                        localStorage.setItem('appHashFromJoin', hash);
                                        localStorage.setItem('appIdFromJoin', id);
                                    }
                                } else {
                                    const { message } = data;

                                    if (message === 'Join script not found') {
                                        this.setState({ isJoinScriptError: true });
                                    } else if (message === 'Inn already use') {
                                        setNotification({
                                            notification: 'inn-already-use',
                                        });
                                    } else if (message === 'Docs not completed') {
                                        setNotification({
                                            notification: 'error-join-docs-not-completed',
                                        });

                                        this.getCorporation().then(
                                            () => {
                                                this.setCurrentStep('docs');
                                            },
                                            () => null,
                                        );
                                    } else if (message === 'Bic is incorrect') {
                                        setNotification({
                                            notification: 'bic-is-error',
                                        });
                                    } else if (message === 'Inn already use') {
                                        this.setCurrentStep('anket');

                                        this.handlerErrors({
                                            errors: [{ name: 'inn', error: 'validate' }],
                                        });

                                        setNotification({
                                            notification: 'inn-already-use',
                                        });
                                    } else if (message === 'Fns account not add') {
                                        setNotification({
                                            notification: 'fns-account-not-add',
                                        });
                                    } else if (message === 'Validation error') {
                                        setNotification({
                                            notification: 'required-fields-not-complete',
                                        });
                                    }
                                }

                                this.handlerLoadingButton(null);
                            },
                            () => null,
                        );
                });
            }
        }
    }

    renderTelegram() {
        return (
            <div className="join__telegram _col">
                <div className="join__telegramButton">
                    <Button className="_blue _crm _normalSize">Связаться с нами в Telegram</Button>
                </div>
                <div className="join__telegramDescription">
                    Есть вопросы? — <b>напишите нам</b>
                    <i className="join__telegramDescriptionIcon">
                        <Icon name="join-telegram-arrow" />
                    </i>
                </div>
            </div>
        );
    }

    renderButton({ name, content, onClick, loadingButton, withArrow = true }) {
        const resultLoadingButton = loadingButton ?? this.state.loadingButton;

        return (
            <div
                className={`join__button _row _click ${
                    resultLoadingButton === name ? '_disabled' : ''
                }`}
                onClick={() => {
                    if (!resultLoadingButton) {
                        if (onClick) {
                            onClick({ name });
                        } else {
                            this.handlerButton({ name });
                        }
                    }
                }}
            >
                <Animate
                    className="join__buttonLoader _loader"
                    isShow={resultLoadingButton === name}
                >
                    <div className="join__buttonLoaderItem _loaderItem">
                        <Loader className="" />
                    </div>
                </Animate>
                {content}
                {withArrow && (
                    <i className="join__buttonIcon">
                        <Icon name="arrow-next-3" />
                    </i>
                )}
            </div>
        );
    }

    scrollToTop() {
        const box = this.parent.current.querySelector('.join__blocks');
        const { scrollTop } = box;

        if (box) {
            setAnimate({
                draw: (progress) => {
                    box.scrollTop = scrollTop - progress * scrollTop;
                },
                duration: 300,
                callback: () => null,
            });
        }
    }

    getLink() {
        const { levels } = this.props;

        return levels[1];
    }

    clear() {
        localStorage.removeItem('crmJoinPhone');
        localStorage.removeItem('crmJoinCode');
        localStorage.removeItem('crmJoinOrganization');
        localStorage.removeItem('crmJoinStep');
        localStorage.removeItem('crmJoinFields');
        localStorage.removeItem('crmJoinCheck');
        localStorage.removeItem('crmJoinComplete');
        localStorage.removeItem('crmJoinCurrentSmzFormType');
        localStorage.removeItem('crmJoinStatusCurrentPage');
        localStorage.removeItem('crmJoinFnsPhone');
        localStorage.removeItem('appHashFromJoin');
        localStorage.removeItem('appIdFromJoin');

        deleteCookie('joinHash');

        this.setCurrentStep('start');

        this.setState({ executorInfo: null, isInit: true }, () => {
            this.initFields();
        });
    }

    getCorporation() {
        const link = this.getLink();

        let phone;

        if (localStorage.getItem('crmJoinPhone')) {
            phone = localStorage.getItem('crmJoinPhone');
        }

        const code = localStorage.getItem('crmJoinCode');

        return new Promise((resolve) => {
            setTimeout(() => {
                axios
                    .get(
                        `${process.env.REACT_APP_API}/join?check=true&link=${link}&${
                            phone ? `phone=${phone}&` : ''
                        }${code ? `code=${code}&` : ''}`,
                        {
                            headers: getHeaders(),
                        },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                const {
                                    name,
                                    hasButton,
                                    joinScript,
                                    fnsStatus,
                                    executorInfo,
                                    isJoin,
                                } = data;

                                if (localStorage.getItem('crmJoinLink') !== link) {
                                    this.clear();
                                }

                                localStorage.setItem('crmJoinLink', link);

                                this.setState(
                                    {
                                        joinScript,
                                        corporationName: name,
                                        hasButton,
                                        executorInfo,
                                        fnsStatus,
                                        currentWalletType:
                                            this.state.currentWalletType ||
                                            (['FL', 'FL-A'].includes(
                                                joinScript.executorOrganization,
                                            )
                                                ? 'card'
                                                : 'account'),
                                        getedCorporationKey: new Date().getTime(),
                                    },
                                    () => {
                                        this.initFields();

                                        if (isJoin) {
                                            this.setFinal();
                                        }

                                        setTimeout(() => {
                                            this.setState({ isInit: true });
                                        }, 100);
                                    },
                                );

                                connectSockets();

                                resolve();
                            } else {
                                const { message, name } = data;

                                if (message === 'Join script not found') {
                                    this.setState({ isJoinScriptError: true });
                                } else if (message === 'Join not found') {
                                    this.clear();
                                    this.setState({ corporationName: name });
                                }
                            }
                        },
                        () => null,
                    );
            }, 300);
        });
    }

    lastX = 0;

    checkTabsPosition() {
        const { currentStep } = this.state;
        const tabs = this.parent.current.querySelector('.join__tabs');
        const tabsInner = tabs.querySelector('.join__tabsInner');
        const currentTab = tabsInner.querySelector(`.join__tabWrapper[data-name="${currentStep}"]`);

        if (currentTab) {
            // tabsInner.style.transform = null;

            const { offsetWidth: tabsWidth } = tabs;
            const { offsetWidth: tabWidth } = currentTab;
            const tabLeft = currentTab.getBoundingClientRect().x;
            const needLeft = tabsWidth / 2 - tabWidth / 2;
            const resultLeft = needLeft - tabLeft;
            this.lastX += resultLeft;

            tabsInner.style.transform = `translate(${this.lastX}px,0)`;
        }
    }

    setFinal() {
        localStorage.setItem('crmJoinCheck', true);
        localStorage.setItem('crmJoinComplete', true);

        this.setCurrentStep('final');
    }

    visibilityDocChange({ detail: { isActive } }) {
        if (isActive) {
            this.getCorporation();
        }
    }

    handlerSocket({ detail }) {
        const { name, data } = detail;

        if (name === 'join') {
            const { isComplete } = data;

            if (isComplete) {
                this.setFinal();
            }
        }
    }

    componentDidMount() {
        this.initFields();
        this.checkTabsPosition();
        this.getCorporation();

        ['export', 'executorPassportCountries', 'executorTowns'].forEach((name) => {
            setServerData(name);
        });

        this.handlerFile = new File({ typeOfFiles: 'docs' });

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('visibilityDocChange', this.visibilityDocChange);
    }

    componentDidUpdate() {
        const { windowIsReady } = this.props;

        if (windowIsReady && !this.windowIsReady) {
            this.windowIsReady = windowIsReady;

            this.checkTabsPosition();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('visibilityDocChange', this.visibilityDocChange);
    }

    render() {
        const { currentStep, isInit, isJoinScriptError, corporationName, updateKey } = this.state;
        const contentHeight = this.getContentHeight();
        const isTabsHide = ['start', 'final'].includes(currentStep);

        return (
            <div ref={this.parent} className={`join ${isInit ? '_init' : ''}`}>
                <div className="join__inner">
                    <div className="join__head _row">
                        <div className="join__logo">
                            <p className="join__logoName">Livecargo</p>
                            <img
                                src={require('../../img/crm/icon-logo.svg').default}
                                alt=""
                                className="join__logoIcon"
                            />
                        </div>
                        <div className="join__headTitle">
                            Подключение
                            <br />
                            <b>к&nbsp;«{corporationName || ''}»</b>
                        </div>
                    </div>
                    <div
                        className={`join__content ${isTabsHide ? '_tabsHide' : ''}`}
                        style={{ height: `${contentHeight}px` }}
                    >
                        <Animate
                            className="join__loader _loader"
                            isShow={!isInit && !isJoinScriptError}
                        >
                            <div className="join__loaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        <Animate className="join__error" isShow={isJoinScriptError}>
                            <img
                                src={require('../../img/emodzi/man-shrugging.png')}
                                alt=""
                                className="join__errorIcon"
                            />
                            <p className="join__errorText">
                                Сценарий не найден.
                                <br />
                                Проверьте ссылку или обратитесь в&nbsp;поддержку
                            </p>
                        </Animate>
                        <div className="join__contentInner">
                            <div className="join__tabs">
                                <div className="join__tabsInner _row">
                                    {this.tabsOrder
                                        .filter((name) => name !== 'final')
                                        .map((name, key) => {
                                            const tab = this.tabs[name];
                                            const currentKey = this.tabsOrder.indexOf(currentStep);
                                            const isComplete = key < currentKey;

                                            return (
                                                <div
                                                    className="join__tabWrapper"
                                                    key={name}
                                                    data-name={name}
                                                >
                                                    <div
                                                        className={`join__tab _${name} ${
                                                            currentStep === name ? '_current' : ''
                                                        } ${isComplete ? '_complete' : ''}`}
                                                    >
                                                        <div className="join__tabInner _row">
                                                            {name !== 'start' && (
                                                                <div className="join__tabCounter _col">
                                                                    {key}
                                                                </div>
                                                            )}
                                                            {tab.content}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <div className="join__blocks">
                                {isInit && (
                                    <ListAbsoluteMain
                                        className="join__blocksInner"
                                        items={this.getBlocks()}
                                        renderItem={this.renderBlock}
                                        classNameItem="join__block"
                                        prop="key"
                                        paramsParent={{ width: true }}
                                        styles={['height']}
                                        parentStyle={
                                            ['start', 'check', 'final'].includes(currentStep)
                                                ? { height: '100%' }
                                                : {}
                                        }
                                        allItems={this.getBlocks(true)}
                                        isNotParamsItem={true}
                                        currentItemKey={currentStep}
                                        callback={({ isChangeLen }) => {
                                            if (isChangeLen) {
                                                this.scrollToTop();
                                            }
                                        }}
                                        keyRender={updateKey}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        heightWindow: state.heightWindow,
        windowIsReady: state.windowIsReady,
        levels: state.levels,
        serverData: state.serverData,
        isNotApp: state.isNotApp,
    };
}

export default connect(mapStateToProps)(Join);

Join.propTypes = {
    user: PropTypes.object,
    heightWindow: PropTypes.number,
    windowIsReady: PropTypes.bool,
    levels: PropTypes.array,
    serverData: PropTypes.object,
    isNotApp: PropTypes.bool,
};
